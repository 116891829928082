
import { Component, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';

import { ALLOWED_MEDIA_UPLOAD_MIMES } from '@/config';

import { getIcons, postIcon } from '@/services/axios';

import Modal from '@/components/Modal.vue';
import SectionH1 from '@/components/heading/SectionH1.vue';

@Component({
  components: {
    Modal,
    SectionH1,
  },
})
export default class MediaUploadModal extends Vue {
  @State opId!: number;

  isUploading: boolean = false;
  selectedFileName: number = 0;
  uploadedPercentage: number = 0;
  icons: any[] = [];
  uploadedFiles: number = 0;

  @Watch('opId')
  onOpIdChange() {
    this.getIcons();
  }

  get ALLOWED_MEDIA_UPLOAD_MIMES(): string {
    return ALLOWED_MEDIA_UPLOAD_MIMES;
  }

  get sortedIcons(): any[] {
    return this.icons.sort((a, b) => a.icon_id - b.icon_id);
  }

  fileChosen(): void {
    // `as any` prevents TS warning
    if (this.$refs.fileInput && (this.$refs.fileInput as any).files) {
      this.selectedFileName = (this.$refs.fileInput as any).files.length;
    }
  }

  getIcons(): void {
    getIcons(this.opId).then((res: any) => (this.icons = res.data));
  }

  uploadFile(): void {
    if (!this.$refs.fileInput || !(this.$refs.fileInput as any).files[0]) return;

    const files = (this.$refs.fileInput as any).files;
    this.uploadedFiles = 0;

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      /* eslint-disable */
      postIcon(this.opId, formData)
        .then(() => this.uploadedFiles++)
        .catch(err => {});
      /* eslint-enable */
    }
  }

  mounted() {
    this.getIcons();
    setInterval(() => {
      this.uploadedPercentage++;
      this.uploadedPercentage %= 101;
    }, Math.round(Math.random() * 1000));
  }
}
